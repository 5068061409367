import client, { Microservice } from '../../utils/http.utils';
import { captureException } from '../../utils/sentry.utils';
import {
  FavouriteProductsResponse,
  Retailer,
  TrackingLink,
  UpdateFavouriteProductsBody,
  WaitlistStatusApiPayload,
  WaitlistStatusApiResponse,
  MarketplaceInterestsBody,
  SortOptionKey,
  LinkPerformanceResponse,
  ConversionPerformanceResponse,
  BestSellersResponse,
  BestSellingResponse,
} from './store';
import {
  MarketplaceFeedbackApi,
  MarketplaceFeedbackFromApi,
  MarketplaceDispatchFn,
} from './types';
import { ErrorMessage } from '../../types/error.types';
import {
  MarketplaceSearchRequest,
  MarketplaceSearchResponse,
} from '../../components/pages/Marketplace/types';

export enum MarketplaceActions {
  requestGetInfluencerRetailersAttempt = 'REQUEST_GET_INFLUENCER_RETAILERS_ATTEMPT',
  requestGetInfluencerRetailersSuccess = 'REQUEST_GET_INFLUENCER_RETAILERS_SUCCESS',
  requestGetInfluencerRetailersFailure = 'REQUEST_GET_INFLUENCER_RETAILERS_FAILURE',
  requestPostTrackingLinkAttempt = 'REQUEST_POST_TRACKING_LINK_ATTEMPT',
  requestPostTrackingLinkSuccess = 'REQUEST_POST_TRACKING_LINK_SUCCESS',
  requestPostTrackingLinkFailure = 'REQUEST_POST_TRACKING_LINK_FAILURE',
  requestGetTrackingLinkAttempt = 'REQUEST_GET_TRACKING_LINK_ATTEMPT',
  requestGetTrackingLinkSuccess = 'REQUEST_GET_TRACKING_LINK_SUCCESS',
  requestGetTrackingLinkFailure = 'REQUEST_GET_TRACKING_LINK_FAILURE',
  requestGetInfluencerInterestsAttempt = 'REQUEST_GET_INFLUENCER_INTEREST_ATTEMPT',
  requestGetInfluencerInterestsSuccess = 'REQUEST_GET_INFLUENCER_INTEREST_SUCCESS',
  requestGetInfluencerInterestsFailure = 'REQUEST_GET_INFLUENCER_INTEREST_FAILURE',
  requestPostInfluencerInterestsAttempt = 'REQUEST_POST_INFLUENCER_INTEREST_ATTEMPT',
  requestPostInfluencerInterestsSuccess = 'REQUEST_POST_INFLUENCER_INTEREST_SUCCESS',
  requestPostInfluencerInterestsFailure = 'REQUEST_POST_INFLUENCER_INTEREST_FAILURE',
  requestPostWaitlistAttempt = 'REQUEST_POST_WAITLIST_ATTEMPT',
  requestPostWaitlistSuccess = 'REQUEST_POST_WAITLIST_SUCCESS',
  requestPostWaitlistFailure = 'REQUEST_POST_WAITLIST_FAILURE',
  requestGetInfluencerFavouriteProductsAttempt = 'REQUEST_GET_INFLUENCER_FAVOURITE_PRODUCTS_ATTEMPT',
  requestGetInfluencerFavouriteProductsSuccess = 'REQUEST_GET_INFLUENCER_FAVOURITE_PRODUCTS_SUCCESS',
  requestGetInfluencerFavouriteProductsFailure = 'REQUEST_GET_INFLUENCER_FAVOURITE_PRODUCTS_FAILURE',
  requestGetInfluencerBestSellingTopPicksAttempt = 'REQUEST_GET_INFLUENCER_BEST_SELLING_TOP_PICKS_ATTEMPT',
  requestGetInfluencerBestSellingTopPicksSuccess = 'REQUEST_GET_INFLUENCER_BEST_SELLING_TOP_PICKS_SUCCESS',
  requestGetInfluencerBestSellingTopPicksFailure = 'REQUEST_GET_INFLUENCER_BEST_SELLING_TOP_PICKS_FAILURE',
  requestPutInfluencerFavouriteProductsAttempt = 'REQUEST_PUT_INFLUENCER_FAVOURITE_PRODUCTS_ATTEMPT',
  requestPutInfluencerFavouriteProductsSuccess = 'REQUEST_PUT_INFLUENCER_FAVOURITE_PRODUCTS_SUCCESS',
  requestPutInfluencerFavouriteProductsFailure = 'REQUEST_PUT_INFLUENCER_FAVOURITE_PRODUCTS_FAILURE',
  requestPostMarketplaceFeedbackAttempt = 'REQUEST_POST_MARKETPLACE_FEEDBACK_FORM_ATTEMPT',
  requestPostMarketplaceFeedbackSuccess = 'REQUEST_POST_MARKETPLACE_FEEDBACK_FORM_SUCCESS',
  requestGetMarketplaceFeedbackFormAttempt = 'REQUEST_GET_MARKETPLACE_FEEDBACK_FORM_ATTEMPT',
  requestGetMarketplaceFeedbackFormSuccess = 'REQUEST_GET_MARKETPLACE_FEEDBACK_FORM_SUCCESS',
  requestGetMarketplaceFeedbackFormFailure = 'REQUEST_GET_MARKETPLACE_FEEDBACK_FORM_FAILURE',
  closeProductTabSearchView = 'CLOSE_PRODUCT_TAB_SEARCH_VIEW',
  openProductTabSearchView = 'OPEN_PRODUCT_TAB_SEARCH_VIEW',
  clearProductFiltersAction = 'CLEAR_PRODUCT_FILTERS_ACTION',
  setProductFiltersAction = 'SET_REQ_PRODUCT_FILTERS_ACTION',
  requestGetInfluencerBestSellersAttempt = 'REQUEST_GET_INFLUENCER_BEST_SELLERS_ATTEMPT',
  requestGetInfluencerBestSellersSuccess = 'REQUEST_GET_INFLUENCER_BEST_SELLERS_SUCCESS',
  requestGetInfluencerBestSellersFailure = 'REQUEST_GET_INFLUENCER_BEST_SELLERS_FAILURE',

  requestGetProductsFromElasticSearchAttempt = 'REQUEST_GET_PRODUCTS_FROM_ELASTIC_SEARCH_ATTEMPT',
  requestGetProductsFromElasticSearchSuccess = 'REQUEST_GET_PRODUCTS_FROM_ELASTIC_SEARCH_SUCCESS',
  requestGetProductsFromElasticSearchFailure = 'REQUEST_GET_PRODUCTS_FROM_ELASTIC_SEARCH_FAILURE',

  clearMarketplacePagedProducts = 'CLEAR_MARKETPLACE_PAGED_PRODUCTS',

  requestGetInfluencerMarketplaceTermsSuccess = 'REQUEST_GET_INFLUENCER_MARKETPLACE_TERMS_SUCCESS',
  requestGetInfluencerMarketplaceTermsAttempt = 'REQUEST_GET_INFLUENCER_MARKETPLACE_TERMS_ATTEMPT',
  requestGetInfluencerMarketplaceTermsFailure = 'REQUEST_GET_INFLUENCER_MARKETPLACE_TERMS_FAILURE',

  requestGetLinkPerformanceAttempt = 'REQUEST_GET_LINK_PERFORMANCE_ATTEMPT',
  requestGetLinkPerformanceSuccess = 'REQUEST_GET_LINK_PERFORMANCE_SUCCESS',
  requestGetLinkPerformanceFailure = 'REQUEST_GET_LINK_PERFORMANCE_FAILURE',
  updateLinkPerformancePagination = 'UPDATE_LINK_PERFORMANCE_PAGINATION',

  requestGetConversionPerformanceAttempt = 'REQUEST_GET_CONVERSION_PERFORMANCE_ATTEMPT',
  requestGetConversionPerformanceSuccess = 'REQUEST_GET_CONVERSION_PERFORMANCE_SUCCESS',
  requestGetConversionPerformanceFailure = 'REQUEST_GET_CONVERSION_PERFORMANCE_FAILURE',
  updateConversionPerformancePagination = 'UPDATE_CONVERSION_PERFORMANCE_PAGINATION',
}

export enum MarketplaceErrors {
  requestGetMarketplaceRetailersError = 'An error occurred while fetching the marketplace retailers.',
  requestGetTrackingLinksError = 'An error occurred while fetching the tracking links.',
  requestPostTrackingLinksError = 'An error occurred while creating the tracking links.',
  requestGetInfluencerFavouriteProductsError = 'An error occurred while getting favourite products.',
  requestGetInfluencerBestSellingTopPicksError = 'An error occurred while getting top picks for best sellers.',
  requestPutInfluencerFavouriteProductsError = 'An error occurred while adding product to favourites.',
  requestGetInfluencerInterestsError = 'An error occurred while fetching interests.',
  requestPostInfluencerInterestsError = 'An error occurred while posting interests.',
  requestPostTrackingLinkFeedbackError = 'An error occurred while posting tracking link feedback.',
  requestGetProductsFromElasticSearchError = 'An error occurred while fetching products',
  requestGetInfluencerBestSellersError = 'An error occurred while getting best sellers',
}

export const CloseProductTabSearchView = async (
  dispatch: MarketplaceDispatchFn
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.closeProductTabSearchView,
    payload: null,
  });
};

export const OpenProductTabSearchView = async (
  dispatch: MarketplaceDispatchFn,
  searchQuery: string
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.openProductTabSearchView,
    payload: searchQuery,
  });
};

export const ClearMarketplacePagedProducts = async (
  dispatch: MarketplaceDispatchFn
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.clearMarketplacePagedProducts,
    payload: null,
  });
};

export const SetIsTrackingLinkFeedbackCompleted = async (
  dispatch: MarketplaceDispatchFn,
  value: boolean
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestPostMarketplaceFeedbackSuccess,
    payload: value,
  });
};

/**
 * RequestGetInfluencerMarketplaceBrands
 *
 * request to GET brands but using POST request method since we are sending a body
 * GET requests with a body are ignored by HTTP
 *
 */
export const RequestGetInfluencerMarketplaceRetailers = async (
  dispatch: MarketplaceDispatchFn,
  programmeIds: string[]
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetInfluencerRetailersAttempt,
  });
  try {
    const response: Retailer[] = await client.request<Retailer[], string[]>(
      Microservice.MARKETPLACE,
      '/advertisers',
      'POST',
      programmeIds
    );

    dispatch({
      type: MarketplaceActions.requestGetInfluencerRetailersSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestGetInfluencerRetailersFailure,
      payload: MarketplaceErrors.requestGetMarketplaceRetailersError,
    });
  }
};

export const RequestGetExistingTrackingLink = async (
  dispatch: MarketplaceDispatchFn,
  influencer_id: string,
  product_id: string
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetTrackingLinkAttempt,
  });
  try {
    const response: TrackingLink = await client.request(
      Microservice.MARKETPLACE,
      `/tracking_link/influencer/${influencer_id}/product/${product_id}`,
      'GET'
    );
    dispatch({
      type: MarketplaceActions.requestGetTrackingLinkSuccess,
      payload: { productId: product_id, response: response },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestGetTrackingLinkFailure,
      payload: MarketplaceErrors.requestGetTrackingLinksError,
    });
  }
};

export const RequestPostCreateTrackingLink = async (
  dispatch: MarketplaceDispatchFn,
  influencer_id: string,
  product_id: string
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestPostTrackingLinkAttempt,
  });
  try {
    const response: TrackingLink = await client.request(
      Microservice.MARKETPLACE,
      `/tracking_link/influencer/${influencer_id}/product/${product_id}`,
      'POST'
    );
    dispatch({
      type: MarketplaceActions.requestPostTrackingLinkSuccess,
      payload: { productId: product_id, response: response },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestPostTrackingLinkFailure,
      payload: MarketplaceErrors.requestPostTrackingLinksError,
    });
  }
};

export const RequestPostWaitlist = async (
  dispatch: MarketplaceDispatchFn,
  waitlistStatus: WaitlistStatusApiPayload,
  influencerId: string
): Promise<void> => {
  try {
    dispatch({
      type: MarketplaceActions.requestPostWaitlistAttempt,
    });
    await client.request(
      Microservice.MARKETPLACE,
      `/waitlist/${influencerId}`,
      'POST',
      waitlistStatus
    );

    dispatch({
      type: MarketplaceActions.requestPostWaitlistSuccess,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestPostWaitlistFailure,
      payload: 'An error occurred while requesting waitlist creation.',
    });
    throw e;
  }
};

export const RequestGetWaitlist = async (
  influencerId: string
): Promise<WaitlistStatusApiResponse> => {
  try {
    const response: WaitlistStatusApiResponse = await client.request(
      Microservice.MARKETPLACE,
      `/waitlist/${influencerId}`,
      'GET'
    );
    return response;
  } catch (e) {
    captureException(e);
    throw e;
  }
};

export const RequestGetInfluencerFavouriteProducts = async (
  dispatch: MarketplaceDispatchFn,
  influencer_id: string
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetInfluencerFavouriteProductsAttempt,
  });
  try {
    const response: FavouriteProductsResponse = await client.request(
      Microservice.MARKETPLACE,
      `/favourite_products/influencer/${influencer_id}`,
      'GET'
    );

    dispatch({
      type: MarketplaceActions.requestGetInfluencerFavouriteProductsSuccess,
      payload: response.favouriteProducts,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestGetInfluencerFavouriteProductsFailure,
      payload: MarketplaceErrors.requestGetInfluencerFavouriteProductsError,
    });
  }
};

export const RequestGetInfluencerBestSellingTopPicks = async (
  dispatch: MarketplaceDispatchFn,
  influencer_id: string
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetInfluencerBestSellingTopPicksAttempt,
  });
  try {
    const response: BestSellingResponse = await client.request(
      Microservice.MARKETPLACE,
      `/best-selling/influencer/${influencer_id}/top-picks`,
      'GET'
    );

    dispatch({
      type: MarketplaceActions.requestGetInfluencerBestSellingTopPicksSuccess,
      payload: response.bestSellingProducts,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestGetInfluencerBestSellingTopPicksFailure,
      payload: MarketplaceErrors.requestGetInfluencerBestSellingTopPicksError,
    });
  }
};

export const RequestPutInfluencerFavouriteProducts = async (
  dispatch: MarketplaceDispatchFn,
  influencer_id: string,
  product_id: string,
  body: UpdateFavouriteProductsBody
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestPutInfluencerFavouriteProductsAttempt,
  });
  try {
    await client.request(
      Microservice.MARKETPLACE,
      `/favourite_products/influencer/${influencer_id}?product_id=${product_id}`,
      'PUT',
      body
    );

    dispatch({
      type: MarketplaceActions.requestPutInfluencerFavouriteProductsSuccess,
      payload: { productId: product_id, action: body.action },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestPutInfluencerFavouriteProductsFailure,
      payload: MarketplaceErrors.requestPutInfluencerFavouriteProductsError,
    });
  }
};

export const RequestGetInfluencerMarketplaceInterests = async (
  dispatch: MarketplaceDispatchFn,
  influencer_id: string
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetInfluencerInterestsAttempt,
  });
  try {
    const response: MarketplaceInterestsBody = await client.request(
      Microservice.MARKETPLACE,
      `/influencer_interests/${influencer_id}`,
      'GET'
    );

    dispatch({
      type: MarketplaceActions.requestGetInfluencerInterestsSuccess,
      payload: response.influencerInterests,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestGetInfluencerInterestsFailure,
      payload: MarketplaceErrors.requestGetInfluencerInterestsError,
    });
  }
};

export const RequestPostInfluencerMarketplaceInterests = async (
  dispatch: MarketplaceDispatchFn,
  influencer_id: string,
  body: MarketplaceInterestsBody
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestPostInfluencerInterestsAttempt,
  });
  try {
    const response: MarketplaceInterestsBody = await client.request(
      Microservice.MARKETPLACE,
      `/influencer_interests/${influencer_id}`,
      'POST',
      body
    );
    dispatch({
      type: MarketplaceActions.requestPostInfluencerInterestsSuccess,
      payload: response.influencerInterests,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestPostInfluencerInterestsFailure,
      payload: MarketplaceErrors.requestPostInfluencerInterestsError,
    });
  }
};

export const RequestPostMarketplaceFeedbackForm = async (
  dispatch: MarketplaceDispatchFn,
  feedback: MarketplaceFeedbackApi,
  influencer_id: string
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestPostMarketplaceFeedbackAttempt,
  });
  try {
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/${influencer_id}/feedback/marketplace`,
      'POST',
      feedback
    );
    dispatch({
      type: MarketplaceActions.requestPostMarketplaceFeedbackSuccess,
      payload: !!response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestPostMarketplaceFeedbackSuccess,
      payload: false,
    });
  }
};

export const RequestGetMarketplaceFeedbackForm = async (
  dispatch: MarketplaceDispatchFn,
  influencer_id: string
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetMarketplaceFeedbackFormAttempt,
  });
  try {
    const response = await client.request<MarketplaceFeedbackFromApi>(
      Microservice.INFLUENCER,
      `/influencer/${influencer_id}/feedback/marketplace`,
      'GET'
    );
    dispatch({
      type: MarketplaceActions.requestGetMarketplaceFeedbackFormSuccess,
      payload: response,
    });
  } catch (e: any) {
    if (e.status === ErrorMessage.NOT_FOUND) {
      dispatch({
        type: MarketplaceActions.requestGetMarketplaceFeedbackFormSuccess,
        payload: {},
      });
    } else {
      captureException(e);
      dispatch({
        type: MarketplaceActions.requestGetMarketplaceFeedbackFormFailure,
        payload: {},
      });
    }
  }
};

export const SetProductFilters = async (
  filters: {
    brands: string[];
    industries: string[];
    partners: string[];
    categories: string[];
  },
  dispatch: MarketplaceDispatchFn
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.setProductFiltersAction,
    payload: filters,
  });
};

export const ClearProductFilters = async (
  filters: {
    brands?: string[];
    industries?: string[];
    partners?: string[];
    categories?: string[];
  },
  dispatch: MarketplaceDispatchFn
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.clearProductFiltersAction,
    payload: filters,
  });
};

export const RequestGetProductsFromElasticSearch = async (
  dispatch: MarketplaceDispatchFn,
  payload: MarketplaceSearchRequest,
  retailers: Retailer[]
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetProductsFromElasticSearchAttempt,
  });
  try {
    const response = await client.request<
      MarketplaceSearchResponse,
      MarketplaceSearchRequest
    >(Microservice.SEARCH, `/v1/products/search`, 'POST', payload);

    dispatch({
      type: MarketplaceActions.requestGetProductsFromElasticSearchSuccess,
      payload: { ...response, retailers },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestGetProductsFromElasticSearchFailure,
      payload: MarketplaceErrors.requestGetProductsFromElasticSearchError,
    });
  }
};

export const RequestGetInfluencerMarketplaceTerms = async (
  dispatch: MarketplaceDispatchFn,
  influencerId: string
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetInfluencerMarketplaceTermsAttempt,
  });
  try {
    const response: Record<'hasAccepted', boolean> = await client.request<
      Record<'hasAccepted', boolean>,
      null
    >(Microservice.MARKETPLACE, `/influencer/${influencerId}/terms`, 'GET');

    dispatch({
      type: MarketplaceActions.requestGetInfluencerMarketplaceTermsSuccess,
      payload: response.hasAccepted,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestGetInfluencerMarketplaceTermsFailure,
    });
  }
};

export const SetHasAcceptedTerms = async (
  value: boolean,
  dispatch: MarketplaceDispatchFn
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetInfluencerMarketplaceTermsSuccess,
    payload: value,
  });
};

export const RequestGetLinkPerformance = async (
  influencerId: string,
  page: number,
  limit: number,
  sort: SortOptionKey,
  dispatch: MarketplaceDispatchFn
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetLinkPerformanceAttempt,
  });
  try {
    const response: LinkPerformanceResponse = await client.request<
      LinkPerformanceResponse,
      null
    >(
      Microservice.MARKETPLACE,
      `/reporting/performance/${influencerId}/links?page=${page}&limit=${limit}&sort=${sort}`,
      'GET'
    );
    dispatch({
      type: MarketplaceActions.requestGetLinkPerformanceSuccess,
      payload: { response: response, sort },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestGetLinkPerformanceFailure,
    });
  }
};

export const RequestGetConversionPerformance = async (
  influencerId: string,
  page: number,
  limit: number,
  dispatch: MarketplaceDispatchFn
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetConversionPerformanceAttempt,
  });
  try {
    const response: ConversionPerformanceResponse = await client.request(
      Microservice.MARKETPLACE,
      `/reporting/performance/${influencerId}/orders?page=${page}&limit=${limit}`,
      'GET'
    );

    dispatch({
      type: MarketplaceActions.requestGetConversionPerformanceSuccess,
      payload: { response: response },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestGetConversionPerformanceFailure,
    });
  }
};

export const RequestGetInfluencerBestSellers = async (
  dispatch: MarketplaceDispatchFn,
  influencerId: string
): Promise<void> => {
  dispatch({
    type: MarketplaceActions.requestGetInfluencerBestSellersAttempt,
  });
  try {
    const response: BestSellersResponse = await client.request(
      Microservice.MARKETPLACE,
      `/best-selling/influencer/${influencerId}/all-products`,
      'GET'
    );

    dispatch({
      type: MarketplaceActions.requestGetInfluencerBestSellersSuccess,
      payload: response.bestSellingProducts,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: MarketplaceActions.requestGetInfluencerBestSellersFailure,
      payload: MarketplaceErrors.requestGetInfluencerBestSellersError,
    });
  }
};
