import ReactGA from 'react-ga4';
import { replaceAmpersandAndUnderscore } from '../../../utils/string.utils';

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks on the Marketplace tab
 * Success
 */
const openMarketplaceTabSuccess = () => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer clicks on the Marketplace tab.',
    label: 'Success',
    value: 1,
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer scrolls on the Marketplace product list
 * Success
 */
const scrollMarketplaceProductListSuccess = () => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer scrolls through the Product List.',
    label: 'Success',
    value: 1,
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer opens the product search in marketplace
 * Attempt
 */
const marketplaceProductSearchAttempt = () => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer uses search feature in the product page.',
    label: 'Attempt',
    value: 1,
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks on a product to see more information in marketplace
 * Attempt
 */
const marketplaceGetProductInformationAttempt = () => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer clicks on a product in Marketplace tab.',
    label: 'Attempt',
    value: 1,
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks apply button in product filters modal
 */
const marketplaceSetProductFilters = (
  advertisers: string[],
  industries: string[]
) => {
  const industriesFormatted = replaceAmpersandAndUnderscore(industries);
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer applies product filters.',
    label: `Retailers: ${advertisers.join(',')} Industries: ${industriesFormatted}`,
    value: advertisers.length + industries.length,
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks on the performance tab to see their reporting
 * Attempt
 */
const marketplacePerformancePageAttempt = () => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer clicks on performance tab in Marketplace.',
    label: 'Performance Tab Attempt',
    value: 1,
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks on the links tab in performance to see their links reporting
 * Attempt
 */
const marketplacePerformanceSubTabAttempt = (tabOption: string) => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer clicks on links/conversion subtab in Performance.',
    label: `SubTab: ${tabOption}`,
    value: 1,
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks on sort option in links subtab
 * Attempt
 */
const marketplaceLinksPerformanceSortOption = (sortOption: string) => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer selects a sort option in links subtab.',
    label: `Sort Option: ${sortOption}`,
    value: 1,
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks on on View More/View Less in links subtab
 * Attempt
 */
const marketplaceLinksPerformanceViewMore = (isExpanded: boolean) => {
  const viewType = isExpanded ? 'More' : 'Less';
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer clicks on View More/Less in Links subtab.',
    label: `View Option: ${viewType}`,
    value: 1,
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks on Link Info in conversion subtab
 * Attempt
 */
const marketplaceConversionPerformanceLinkInfo = () => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer clicks on Link Info in conversion subtab.',
    label: `Performance - Conversion - Link Info Attempt`,
    value: 1,
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks on Link Info in conversion subtab
 * Attempt
 */
const marketplaceBestSellingTrackingLinkCreation = (origin: string) => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer created a tracking link from a best seller product',
    label: `Page Origin: ${origin}`,
    value: 1,
  });
};

export default {
  openMarketplaceTabSuccess,
  scrollMarketplaceProductListSuccess,
  marketplaceProductSearchAttempt,
  marketplaceGetProductInformationAttempt,
  marketplaceSetProductFilters,
  marketplacePerformancePageAttempt,
  marketplaceLinksPerformanceSortOption,
  marketplaceLinksPerformanceViewMore,
  marketplacePerformanceSubTabAttempt,
  marketplaceConversionPerformanceLinkInfo,
  marketplaceBestSellingTrackingLinkCreation,
};
